import { useModal } from "vue-final-modal";
import BaseAlert from "~/components/Base/Alert.vue";

export const useBaseAlert = (
  title: string,
  message: any,
  isMessageComponent?: boolean
) => {
  const { open, close } = useModal({
    component: BaseAlert,
    attrs: {
      title,
      message,
      isMessageComponent: isMessageComponent ?? false,
      onConfirm: () => {
        close();
      },
    },
  });

  open();
};
